<template>
  <div>
    <b-link
      href="/cnno2021/trabalhos-cientificos/envio/"
      variant="success"
      style="margin-right: 5px"
      class="btn btn-success"
    >
      <feather-icon
        icon="PaperclipIcon"
      />
      Enviar trabalho
    </b-link>
    <b-link
      href="https://bit.ly/cnno2021_edital"
      target="_blank"
      variant="primary"
      style="margin-left: 5px"
      class="btn btn-primary"
    >
      <feather-icon
        icon="FileIcon"
      />
      Edital
    </b-link>
    <b-card>
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        style="margin-top: 10px;"
      >
        <template #cell(n)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status == 'Aceito'"
            variant="success"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-if="data.item.status == 'Cancelado'"
            variant="danger"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-if="data.item.status == 'Em analise'"
            variant="primary"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(acoes)="data">
          <b-button
            variant="primary"
          >
            <feather-icon
              icon="EditIcon"
            /> Editar {{ data.item.id }}
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, BLink } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BLink,
  },
  data() {
    return {
      items: [
        {
          id: 1, title: 'Desafios no dia a dia do oftalmologista.', status: 'Em analise'
        },
        {
          id: 2, title: 'Cornea transversa', status: 'Aceito'
        },
      ],
      fields: [
        {
          key: 'n',
          label: 'Nº',
          sortable: true
        },
        {
          key: 'title',
          label: 'Titulo',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
          sortable: true,
        }
      ],
    }
  }
}
</script>

<style>

</style>
